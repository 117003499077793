import React from "react"
import Layout from "../components/LayoutComponent"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { Form, Row, Col, Button, Spinner } from "react-bootstrap"
import netlifyStyle from "../../site/settings/style.json"
import Contact from "../components/ContactModal"
import ContactForm from "../components/ContactForm"

function contact() {
  return (
    <div>
      <Layout>
        <h1 className="text-center mb-3">Sign up for our newsletter</h1>
        <ContactForm />
      </Layout>
    </div>
  )
}

export default contact

/*




*/

/* 

    Mail Chimp Form Action

    <form action="https://gmail.us2.list-manage.com/subscribe/post" method="POST" _lpchecked="1">
    <input type="hidden" name="u" value="576a851438e250c8aa4fa6a87">
    <input type="hidden" name="id" value="f2eaea7ae3">
    <input type="hidden" name="orig-lang" value="1">


     <input
                type="address"
                onChange={e =>
                  this._handleAddressChange(e.target.value, e.target.name)
                }
                placeholder="Address Line 2"
                name="addr2"
              />

*/
